import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import { TargetAddressContext } from '../../../state/contexts';
import '../../pages.css';

function DNSEnum() {

    // const [showTooltip, setShowTooltip] = useState(false)
    // const [show, setShow] = useState(false);
    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>DNSEnum</h4>
            <p className="mod-description">
                Multithreaded perl script to enumerate DNS information of a domain and to discover non-contiguous ip blocks.
                </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Dnsenum help. (-h)</p>
                            <p style={{ color: "#5797b7" }}>dnsenum -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='dnsenum -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Basic domain enumeration. Must be a domain name, not an IP.</p>
                            <p style={{ color: "#5797b7" }}>dnsenum {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`dnsenum ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of dnsenum commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/information-gathering/dnsenum</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/information-gathering/dnsenum'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default DNSEnum
