import React from 'react';
import WebRecon2 from './community/WebRecon2';
import PingSweep from './community/PingSweep';
import '../pages.css';

function CommunityEnumScripts() {

    return (

        <div>
            <WebRecon2 />
            <PingSweep />
        </div>
    )
}

export default CommunityEnumScripts
