import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import { TargetAddressContext } from '../../../state/contexts';
import '../../pages.css';

function Nikto() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Nikto</h4>
            <p className="mod-description">
                Nikto is an Open Source (GPL) web server scanner which performs comprehensive tests against web servers for multiple items, including over 6700 potentially dangerous files/programs, checks for outdated versions of over 1250 servers, and version specific problems on over 270 servers. It also checks for server configuration items such as the presence of multiple index files, HTTP server options, and will attempt to identify installed web servers and software. Scan items and plugins are frequently updated and can be automatically updated.
        </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Nikto help. (-H) </p>
                            <p style={{ color: "#5797b7" }}>nikto -H</p>
                        </td>
                        <td>
                            <CopyToClipboard text='nikto -H'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Webserver scan. (-h) </p>
                            <p style={{ color: "#5797b7" }}>nikto -h {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`nikto -h ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Webserver scan on specific port. (-p) </p>
                            <p style={{ color: "#5797b7" }}>nikto -h {targetAddress} -p 8080</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`nikto -h ${targetAddress} -p 8080`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Webserver scan to include /cgi directories. (-C all) </p>
                            <p style={{ color: "#5797b7" }}>nikto -h {targetAddress} -C all</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`nikto -h ${targetAddress} -C all`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Nikto commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/information-gathering/nikto</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/information-gathering/nikto'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>

                </tbody >
            </Table >
        </div>
    )
}

export default Nikto