import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import { TargetAddressContext } from '../../../state/contexts';
import '../../pages.css';

function CeWL() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>CeWL</h4>
            <p className="mod-description">
                CeWL is a ruby app which spiders a given url to a specified depth, optionally following external links, and returns a list of words which can then be used for password crackers such as John the Ripper.
                </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>CeWL help. (--help)</p>
                            <p style={{ color: "#5797b7" }}>cewl --help</p>
                        </td>
                        <td>
                            <CopyToClipboard text='cewl --help'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Scan to a depth of 2 (-d 2) and use a minimum word length of 5 (-m 5), save the words to a file (-w cewlwords.txt), targeting the given URL (https://example.com):</p>
                            <p style={{ color: "#5797b7" }}>cewl -d 2 -m 5 -w cewlwords.txt https://{targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`cewl -d 2 -m 5 -w cewlwords.txt https://${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of CeWL commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/cewl</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/cewl'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default CeWL
