import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Msf() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Metasploit Framework</h4>
            <p className="mod-description">
                Metasploit is a penetration testing platform that enables you to find, exploit, and validate vulnerabilities. It provides the infrastructure, content, and tools to perform penetration tests and extensive security auditing and thanks to the open source community and Rapid7’s own hard working content team, new modules are added on a regular basis, which means that the latest exploit is available to you as soon as it’s published.
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Metasploit help. (-h) </p>
                            <p style={{ color: "#5797b7" }}>msfconsole -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfconsole -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To launch Metasploit Framework</p>
                            <p style={{ color: "#5797b7" }}>msfconsole</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfconsole'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To cycle through the various msf banners, type banner.</p>
                            <p style={{ color: "#5797b7" }}>banner</p>
                        </td>
                        <td>
                            <CopyToClipboard text='banner'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To search for attack modules in the Metasploit Framework</p>
                            <p style={{ color: "#5797b7" }}>Search all or search for specific module or searchstring</p>
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Example:</p>
                            <p style={{ color: "#5797b7" }}>search eternalblue</p>
                        </td>
                        <td>
                            <CopyToClipboard text='search eternalblue'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To use a specific attack module.</p>
                            <p style={{ color: "#5797b7" }}>use exploit/exploit path</p>
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Example:</p>
                            <p style={{ color: "#5797b7" }}>use exploit/windows/smb/ms17_010_psexec</p>
                        </td>
                        <td>
                            <CopyToClipboard text='use exploit/windows/smb/ms17_010_psexec'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To show the options available once an attack module has been selected.</p>
                            <p style={{ color: "#5797b7" }}>show options</p>
                        </td>
                        <td>
                            <CopyToClipboard text='show options'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To set an option from the show options list.</p>
                            <p style={{ color: "#5797b7" }}>set option</p>
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Example:</p>
                            <p style={{ color: "#5797b7" }}>set rhosts {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`set rhosts ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To launch the exploit.</p>
                            <p style={{ color: "#5797b7" }}>exploit</p>
                        </td>
                        <td>
                            <CopyToClipboard text='exploit'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Once you have a shell, you can use the meterpreter command to launch meterpreter shell.</p>
                            <p style={{ color: "#5797b7" }}>meterpreter</p>
                        </td>
                        <td>
                            <CopyToClipboard text='meterpreter'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Push the current interactive session to the background.</p>
                            <p style={{ color: "#5797b7" }}>background</p>
                        </td>
                        <td>
                            <CopyToClipboard text='background'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To list all background sessions.</p>
                            <p style={{ color: "#5797b7" }}>sessions -l</p>
                        </td>
                        <td>
                            <CopyToClipboard text='sessions -l'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To interact with a background session.</p>
                            <p style={{ color: "#5797b7" }}>sessions -i sessionID</p>
                        </td>
                        <td>
                            <CopyToClipboard text='sessions -i sessionID'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Metasploit commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/exploitation-tools/metasploit-framework</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/exploitation-tools/metasploit-framework'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Metasploit cheat sheet can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://www.sans.org/security-resources/sec560/misc_tools_sheet_v1.pdf</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://www.sans.org/security-resources/sec560/misc_tools_sheet_v1.pdf'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Msf