import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Hydra() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Hydra</h4>
            <p className="mod-description">
                Hydra is a parallelized login cracker which supports numerous protocols to attack. It is very fast and flexible, and new modules are easy to add. This tool makes it possible for researchers and security consultants to show how easy it would be to gain unauthorized access to a system remotely.
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Hydra help. (-h) </p>
                            <p style={{ color: "#5797b7" }}>hydra -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='hydra -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Attempt to login as the root user (-l root) using a password list (-P rockyou.txt) in verbose mode (v) with 50 threads (-t 50)</p>
                            <p style={{ color: "#5797b7" }}>hydra -l root -P rockyou.txt -v {targetAddress} -t 6</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`hydra -l root -P rockyou.txt -v ${targetAddress} -t 50`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Hydra commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/hydra</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/hydra'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Hydra
