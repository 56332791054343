import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function KaliWordlists() {

    return (
        <div className="mod-container">
            <h4>Kali Wordlists</h4>
            <p className="mod-description">
                This package contains the rockyou wordlist and contains symlinks to a number of other password files present in the Kali Linux distribution. This package has an installation size of 134 MB.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Show a list of all wordlists in Kali.</p>
                            <p style={{ color: "#5797b7" }}>ls -lh /usr/share/wordlists/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='ls -lh /usr/share/wordlists/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>root@kali:~# ls -lh /usr/share/wordlists/
                            <br />
                            ***Depending on your version you may have a different output than what is listed below.
                            </p>
                            <p>
                                total 51M
                                </p>
                            <p style={{ color: "#5797b7" }}>
                                <text style={{ color: "white" }}>dirb -&#62; </text> /usr/share/dirb/wordlists
                                <br />
                                <text style={{ color: "white" }}>dirbuster -&#62; </text> /usr/share/dirbuster/wordlists
                                <br />
                                <text style={{ color: "white" }}>dnsmap.txt -&#62; </text> /usr/share/dnsmap/wordlist_TLAs.txt
                                <br />
                                <text style={{ color: "white" }}>fasttrack.txt -&#62; </text> /usr/share/set/src/fasttrack/wordlist.txt
                                <br />
                                <text style={{ color: "white" }}>fern-wifi -&#62; </text> /usr/share/fern-wifi-cracker/extras/wordlists
                                <br />
                                <text style={{ color: "white" }}>metasploit -&#62; </text> /usr/share/metasploit-framework/data/wordlists
                                <br />
                                <text style={{ color: "white" }}>nmap.lst -&#62; </text> /usr/share/nmap/nselib/data/passwords.lst
                                <br />
                                rockyou.txt.gz
                                <br />
                                <text style={{ color: "white" }}>sqlmap.txt -&#62; </text> /usr/share/sqlmap/txt/wordlist.txt
                                <br />
                                <text style={{ color: "white" }}>wfuzz -&#62; </text> /usr/share/wfuzz/wordlist
                                </p>
                        </td>
                        <td>
                            <CopyToClipboard text={`ls -lh /usr/share/wordlists/`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>More information on Kali wordlists can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/wordlists</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/wordlists'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default KaliWordlists
