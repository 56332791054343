import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Wfuzz() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Wfuzz</h4>
            <p className="mod-description">
                Wfuzz is a tool designed for bruteforcing Web Applications, it can be used for finding resources not linked (directories, servlets, scripts, etc), bruteforce GET and POST parameters for checking different kind of injections (SQL, XSS, LDAP,etc), bruteforce Forms parameters (User/Password), Fuzzing,etc.
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Wfuzz full help list. (--help)</p>
                            <p style={{ color: "#5797b7" }}>wfuzz --help</p>
                        </td>
                        <td>
                            <CopyToClipboard text='wfuzz --help'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Use colour output (-c), a wordlist as a payload (-z file,/usr/share/wfuzz/wordlist/general/common.txt), and hide 404 messages (–hc 404) to fuzz the given URL (http://{targetAddress}/FUZZ).</p>
                            <p style={{ color: "#5797b7" }}>wfuzz -c -z file,/usr/share/wfuzz/wordlist/general/common.txt --hc 404 http://{targetAddress}/FUZZ</p>
                            <p>***Note, you must add /FUZZ to the end of your target URL in order for the script to run.</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`wfuzz -c -z file,/usr/share/wfuzz/wordlist/general/common.txt --hc 404 http://${targetAddress}/FUZZ`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Wfuzz commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/web-applications/wfuzz</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/web-applications/wfuzz'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>

    )
}

export default Wfuzz