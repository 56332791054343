import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import ExploitationTools from './exploits/ExploitationTools';
import PasswordAttacks from './exploits/PasswordAttacks';
import Wordlists from './exploits/Wordlists';
import './pages.css';

function Exploits() {
    return (
        <div className="page-container">
            <div className="page-header">Exploits</div>
            <Accordion>
                <Card>
                    <Card.Header>
                        Exploitation Tools
                            <Accordion.Toggle as={Button} variant="info" eventKey="0">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body><ExploitationTools /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Login & Password Attacks
                            <Accordion.Toggle as={Button} variant="info" eventKey="1">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body><PasswordAttacks /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Wordlists
                            <Accordion.Toggle as={Button} variant="info" eventKey="2">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body><Wordlists /></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default Exploits