import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { convertStringToRot13, convertRot13ToString } from './Rot13Data';
import '../pages.css';


function Rot13() {

    const [encodeTextAreaValue, setEncodeTextAreaValue] = useState("");
    const [decodeTextAreaValue, setDecodeTextAreaValue] = useState("");

    const handleEncodeTextAreaChange = (event) => {
        setEncodeTextAreaValue(event.currentTarget.value);
    };

    const handleDecodeTextAreaChange = (event) => {
        setDecodeTextAreaValue(event.currentTarget.value);
    };

    const rot13Encode = () => {
        setDecodeTextAreaValue(convertStringToRot13(encodeTextAreaValue));
        // setDecodeTextAreaValue(convertStringToRot13(encodeTextAreaValue).join(' '));
    };

    const rot13Decode = () => {
        setEncodeTextAreaValue(convertRot13ToString(decodeTextAreaValue));
        // setEncodeTextAreaValue(convertRot13ToString(decodeTextAreaValue).join(''));
    };

    return (
        <div className="mod-container">
            <Container>
                <Row>
                    <Col sm={6}>
                        <div>
                            <textarea className="encryption-textarea" value={encodeTextAreaValue} onChange={handleEncodeTextAreaChange}></textarea>
                        </div>
                        <div>
                            <Button variant="info" onClick={rot13Encode} id="encode">Encode</Button>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <textarea className="encryption-textarea" value={decodeTextAreaValue} onChange={handleDecodeTextAreaChange}></textarea>
                        </div>
                        <div>
                            <Button variant="info" onClick={rot13Decode} id="decode">Decode</Button>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Rot13