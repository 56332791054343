import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function KaliShells() {

    return (
        <div className="mod-container">
            <h4>Kali Shells</h4>
            <p className="mod-description">
                A collection of webshells for ASP, ASPX, CFM, JSP, Perl, and PHP servers available in Kali.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Shows a list of all webshells in Kali.</p>
                            <p style={{ color: "#5797b7" }}>tree /usr/share/webshells/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='tree /usr/share/webshells/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>root@kali:~# tree /usr/share/webshells/
                            <br />
                            ***Depending on your version you may have a different output than what is listed below.
                            </p>
                            <p>
                                /usr/share/webshells/
                                </p>
                            <p style={{ color: "#5797b7" }}>
                                ├── <text style={{ color: "white" }}>asp</text>
                                <br />
                                │   ├── cmd-asp-5.1.asp
                                <br />
                                │   └── cmdasp.asp
                                <br />
                                ├── <text style={{ color: "white" }}>aspx</text>
                                <br />
                                │   └── cmdasp.aspx
                                <br />
                                ├── <text style={{ color: "white" }}>cfm</text>
                                <br />
                                │   └── cfexec.cfm
                                <br />
                                ├── <text style={{ color: "white" }}>jsp</text>
                                <br />
                                │   ├── cmdjsp.jsp
                                <br />
                                │   └── jsp-reverse.jsp
                                <br />
                                ├── <text style={{ color: "white" }}>perl</text>
                                <br />
                                │   ├── perlcmd.cgi
                                <br />
                                │   └── perl-reverse-shell.pl
                                <br />
                                └── <text style={{ color: "white" }}>php</text>
                                <br />
                                    ├── findsock.c
                                    <br />
                                    ├── php-backdoor.php
                                    <br />
                                    ├── php-findsock-shell.php
                                    <br />
                                    ├── php-reverse-shell.php
                                    <br />
                                    ├── qsd-php-backdoor.php
                                    <br />
                                    └── simple-backdoor.php
                                </p>
                            <p style={{ color: "white" }}>6 directories, 14 files</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>More information on Kali webshells can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/maintaining-access/webshells</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/maintaining-access/webshells'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default KaliShells
