import React from 'react'
import CeWL from './wordlists/CeWL';
import Crunch from './wordlists/Crunch';
import KaliWordlists from './wordlists/KaliWordlists';

function Wordlists() {
    return (
        <div>
            <CeWL />
            <Crunch />
            <KaliWordlists />
        </div>
    )
}

export default Wordlists
