
export const convertStringToHex = (str) => {
    // eslint-disable-next-line newline-after-var
    let rawStringLength = str.length;
    let hex = new Array(rawStringLength);

    while (rawStringLength--) {
        const charCode = str
            .charCodeAt(rawStringLength)
            .toString(16)
            .toUpperCase();

        // if char.length === 1, prefix with a 0 to display uniform columns and rows
        hex[rawStringLength] = charCode.length === 2 ? charCode : `0${charCode}`;
    }

    return hex;
};


export const convertHexToString = (str, delimiter = " ") => {
    // eslint-disable-next-line newline-after-var
    const hexArray = str.split(delimiter);
    const stringArray = hexArray.map((hexvalue) => {
        const decimalValue = parseInt(hexvalue, 16);
        return String.fromCharCode(decimalValue);
    });

    return stringArray;
};
