import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function JohnTheRipper() {
    return (
        <div className="mod-container">
            <h4>John The Ripper</h4>
            <p className="mod-description">
                John the Ripper is designed to be both feature-rich and fast. It combines several cracking modes in one program and is fully configurable for your particular needs (you can even define a custom cracking mode using the built-in compiler supporting a subset of C). Also, John is available for several different platforms which enables you to use the same cracker everywhere (you can even continue a cracking session which you started on another platform).
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>John The Ripper help.</p>
                            <p style={{ color: "#5797b7" }}>john</p>
                        </td>
                        <td>
                            <CopyToClipboard text='john'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Using a wordlist (–wordlist=/usr/share/john/password.lst), apply mangling rules (–rules) and attempt to crack the password hashes in the given file (unshadowed.txt):</p>
                            <p style={{ color: "#5797b7" }}>john --wordlist=/usr/share/john/password.lst --rules unshadowed.txt</p>
                        </td>
                        <td>
                            <CopyToClipboard text='john --wordlist=/usr/share/john/password.lst --rules unshadowed.txt'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of John The Ripper commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/john</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/john'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default JohnTheRipper