import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Dirb() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Dirb</h4>
            <p className="mod-description">
                DIRB is a Web Content Scanner. It looks for existing (and/or hidden) Web Objects. It basically works by launching a dictionary based attack against a web server and analyzing the response.
            <br />
                DIRB comes with a set of preconfigured attack wordlists for easy usage but you can use your custom wordlists. Also DIRB sometimes can be used as a classic CGI scanner, but remember is a content scanner not a vulnerability scanner.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Dirb help. </p>
                            <p style={{ color: "#5797b7" }}>dirb</p>
                        </td>
                        <td>
                            <CopyToClipboard text='dirb'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Webserver directory buster. </p>
                            <p style={{ color: "#5797b7" }}>dirb http://{targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`dirb http://${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Webserver directory buster on a specific port. (:) </p>
                            <p style={{ color: "#5797b7" }}>dirb http://{targetAddress}:8080</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`dirb http://${targetAddress}:8080`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Webserver directory buster non recursively. (-r) </p>
                            <p style={{ color: "#5797b7" }}>dirb http://{targetAddress} -r</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`dirb http://${targetAddress} -r`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Dirb commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/web-applications/dirb</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/web-applications/dirb'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Dirb
