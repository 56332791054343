import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Form from '../pages/checklist/Form';
import TodoList from '../pages/checklist/TodoList';
import '../pages/checklist/Checklist.css';
import './pages.css';

function CheckList() {

  //State stuff
  const [inputText, setInputText] = useState("");
  const [todos, setTodos] = useState([]);
  const [status, setStatus] = useState("all");
  const [filteredTodos, setFilteredTodos] = useState([]);

  useEffect(() => {
    getLocalTodos();
  }, []);

  //Use Effect
  useEffect(() => {
    filterHandler();
    saveLocalTodos();
  }, [todos, status]);

  const filterHandler = () => {
    switch (status) {
      case 'completed':
        setFilteredTodos(todos.filter(todo => todo.completed === true))
        break;
      case 'uncompleted':
        setFilteredTodos(todos.filter(todo => todo.completed === false))
        break;
      default:
        setFilteredTodos(todos);
        break;
    }
  };

  //Local Storage
  const saveLocalTodos = () => {
    localStorage.setItem('todos', JSON.stringify(todos));
  };
  const getLocalTodos = () => {
    if (localStorage.getItem('todos') === null) {
      localStorage.setItem('todos', JSON.stringify([]));
    }
    else {
      let todoLocal = JSON.parse(localStorage.getItem('todos'));
      setTodos(todoLocal);
    }
  };

  return (

    <div className="page-container">
      <Card>
        <div className="page-header">Check List</div>
        <div className="mod-container">
          <Card.Body>
            <Card.Text>
              <Form
                inputText={inputText}
                todos={todos}
                setTodos={setTodos}
                setInputText={setInputText}
                setStatus={setStatus}
              />
              <TodoList
                filteredTodos={filteredTodos}
                setTodos={setTodos}
                todos={todos}
              />
            </Card.Text>
          </Card.Body>
        </div>
      </Card>
      <div className="checklist-bottom-margin"></div>
    </div>
  );
}

export default CheckList;
