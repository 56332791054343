import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function HashIdentifier() {
    return (
        <div className="mod-container">
            <h4>Hash Identifier</h4>
            <p className="mod-description">
                Software to identify the different types of hashes used to encrypt data and especially passwords.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Hash-Identifier usage.</p>
                            <p style={{ color: "#5797b7" }}>hash-identifier</p>
                        </td>
                        <td>
                            <CopyToClipboard text='hash-ideitifier'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of hashs can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/hash-identifier</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/hash-identifier'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <hr />
        </div>
    )
}

export default HashIdentifier