import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { GiLogging } from 'react-icons/gi';
import { GiConsoleController } from 'react-icons/gi';
import { GoPlusSmall } from 'react-icons/go';
import './flags.css';


function FlagOne() {

    const { handleSubmit, register, errors } = useForm();
    const onSubmit = values => (values);
    console.log("Q29uZ3JhdHMhIFlvdSBmb3VuZCB0aGUgZmlyc3QgZmxhZyEKe0NvbnNvbGUuTG9nfQ==")

    return (
        <Container>
            <div className="flag-container">
                <GiConsoleController /> <GoPlusSmall /> <GiLogging />
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <input
                    name="text"
                    placeholder="{Flag}"
                    ref={register({
                        validate: value => value !== "{Console.Log}" || "Success!"
                    })}
                />
                <div className="flag-submit-button">
                    <Button type="submit" variant="info">Submit</Button>
                </div>
            </Form>
            <div className="flag-success">
                {errors.text && errors.text.message}
            </div>
        </Container>
    );
}

export default FlagOne