import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import { TargetAddressContext } from '../../../state/contexts';
import '../../pages.css';

function DNSRecon() {

    // const [showTooltip, setShowTooltip] = useState(false)
    // const [show, setShow] = useState(false);
    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>DNSRecon</h4>
            <p className="mod-description">
                DNSRecon – A powerful DNS enumeration script.
                </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Dnsrecon help. (-help)</p>
                            <p style={{ color: "#5797b7" }}>dnsrecon -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='dnsrecon -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Basic domain enumeration. (-d) Must be a domain name, not an IP.</p>
                            <p style={{ color: "#5797b7" }}>dnsrecon -d {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`dnsrecon -d ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of dnsrecon commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/information-gathering/dnsrecon</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/information-gathering/dnsrecon'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default DNSRecon
