import React from 'react';
import { Table } from 'react-bootstrap';
import '../../pages.css';

function LinuxPrivesc() {
    return (
        <div className="mod-container">
            <h4>Linux Privilage Escalation</h4>
            <p className="mod-description">
                A short list of manual commands you can use to try and elevate your privilages.
            </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>A python script to spawn a tty shell.</p>
                            <p style={{ color: "#5797b7" }}>python -c 'import pty;pty.spawn("/bin/bash")' </p>
                            <p style={{ color: "#5797b7" }}>python -c 'import pty;pty.spawn("/bin/sh")' </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Displays a list of commands you can run as root or as other users on the host.</p>
                            <p style={{ color: "#5797b7" }}>sudo -l</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Displays a list of commands you can run with elevated privileges. (SUID)</p>
                            <p style={{ color: "#5797b7" }}>find / -perm -u=s -type f 2&#62;/dev/null</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Displays a list of writable dirs.</p>
                            <p style={{ color: "#5797b7" }}>find / -writable -type d 2&#62;/dev/null</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Displays a list of commands you can run with elevated privileges.</p>
                            <p style={{ color: "#5797b7" }}>cat /etc/sudoers</p>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default LinuxPrivesc