import React from 'react'
import { SiHackaday } from 'react-icons/si';
import '../App.css';


function Home() {
    return (
        <div>
            <div className="App-header">
                <SiHackaday className="App-logo" />
                <br />
                <h1>CTF ToolKit</h1>
            </div>
        </div>
    );
}
export default Home