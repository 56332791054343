import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Hashcat() {
    return (
        <div className="mod-container">
            <h4>Hashcat</h4>
            <p className="mod-description">
                hashcat is the world’s fastest and most advanced password recovery utility, supporting five unique modes of attack for over 200 highly-optimized hashing algorithms. hashcat currently supports CPUs, GPUs, and other hardware accelerators on Linux, Windows, and OSX, and has facilities to help enable distributed password cracking.
                </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Hashcat help. (--help) </p>
                            <p style={{ color: "#5797b7" }}>hashcat --help</p>
                        </td>
                        <td>
                            <CopyToClipboard text='hashcat --help'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Attempt to crack the hash using attack mode Straight ( -a 0) for MD5 (-m 0) with Performance set to Insane ( -w 4 ) against your list of hashes (thehash.txt) using the rockyou list (rockyou.txt) and outputting the results in the file cracked.txt (-o).</p>
                            <p style={{ color: "#5797b7" }}>hashcat -a 0 -m 0 -w 4 thehash.txt rockyou.txt -o cracked.txt</p>
                        </td>
                        <td>
                            <CopyToClipboard text='hashcat -a 0 -m 0 -w 4 thehash.txt rockyou.txt -o cracked.txt'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Hashcat commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/hashcat</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/hashcat'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Hashcat