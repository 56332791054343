import React from 'react';
import { GiAlienBug } from 'react-icons/gi'
import '../App.css';


function NoMatch() {
    return (
        <div>
            <div className="App-header">
                <GiAlienBug className='nomatch-icon' alt="logo" />
                <p>
                    <br />
                    <h2>Take me to your leader...</h2>
                </p>
            </div>
        </div>
    );
}

export default NoMatch