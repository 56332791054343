import React from 'react';
import '../pages/notes/notes.css';
import NotepadData from './notes/NotesData';

function Notes() {
  return (

    <div className="notes-container">
      {<NotepadData />}
    </div >
  );
}

export default Notes;