import React from 'react'
import Msf from './exploitationtools/Msf';
import Msfpc from './exploitationtools/Msfpc';
import Sqlmap from './exploitationtools/Sqlmap';


function Frameworks() {
    return (
        <div>
            <Msf />
            <Msfpc />
            <Sqlmap />
        </div>
    )
}

export default Frameworks
