import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function WhatWeb() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>WhatWeb</h4>
            <p className="mod-description">
                WhatWeb identifies websites. Its goal is to answer the question, “What is that Website?”. WhatWeb recognises web technologies including content management systems (CMS), blogging platforms, statistic/analytics packages, JavaScript libraries, web servers, and embedded devices. WhatWeb has over 1700 plugins, each to recognise something different. WhatWeb also identifies version numbers, email addresses, account IDs, web framework modules, SQL errors, and more.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Whatweb help. (-h)</p>
                            <p style={{ color: "#5797b7" }}>whatweb -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='whatweb -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Whatweb scanning in verbose mode (-v) using max aggression (-a 4). </p>
                            <p style={{ color: "#5797b7" }}>whatweb -v -a 4 {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`whatweb -v -a 4 ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of WhatWeb commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/web-applications/whatweb</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/web-applications/whatweb'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default WhatWeb
