import React from 'react'
import Enum4Linux from './assets/Enum4Linux';




function Assets() {
    return (
        <div>
            <Enum4Linux />
        </div>
    )
}

export default Assets
