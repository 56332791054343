import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../pages.css';

function Base64() {
    const [encodeTextAreaValue, setEncodeTextAreaValue] = useState("");
    const [decodeTextAreaValue, setDecodeTextAreaValue] = useState("");

    const handleEncodeTextAreaChange = (event) => {
        setEncodeTextAreaValue(event.currentTarget.value);
    };

    const handleDecodeTextAreaChange = (event) => {
        setDecodeTextAreaValue(event.currentTarget.value);
    };

    const base64Encode = () => {
        setDecodeTextAreaValue(btoa(encodeTextAreaValue));
    };

    const base64Decode = () => {
        setEncodeTextAreaValue(atob(decodeTextAreaValue));
    };

    return (
        <div className="mod-container">
            <Container>
                <Row>
                    <Col sm={6}>
                        <div>
                            <textarea className="encryption-textarea" value={encodeTextAreaValue} onChange={handleEncodeTextAreaChange}></textarea>
                        </div>
                        <div>
                            <Button variant="info" onClick={base64Encode} id="encode">Encode</Button>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <textarea className="encryption-textarea" value={decodeTextAreaValue} onChange={handleDecodeTextAreaChange}></textarea>
                        </div>
                        <div>
                            <Button variant="info" onClick={base64Decode} id="decode">Decode</Button>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Base64