import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Wpscan() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>WPScan</h4>
            <p className="mod-description">
                WPScan is a black box WordPress vulnerability scanner that can be used to scan remote WordPress installations to find security issues.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Wordpress scan. (--help) </p>
                            <p style={{ color: "#5797b7" }}>wpscan --help</p>
                        </td>
                        <td>
                            <CopyToClipboard text='wpscan --help'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Wordpress scan to bruteforce password with 50 threads.</p>
                            <p style={{ color: "#5797b7" }}>wpscan --url http://{targetAddress} --passwords rockyou.txt --usernames admin –max-threads 50</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`wpscan --url http://${targetAddress} --passwords rockyou.txt --usernames admin –max-threads 50`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of WPscan commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/web-applications/wpscan</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/web-applications/wpscan'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Wpscan