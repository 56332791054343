import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../pages.css';

function CyberChef() {


    return (
        <div className="spacer">
            <Table striped variant="dark">
                <thead>
                    <tr>
                        <th>
                            <p>Encryption and Cipher Encoders/Decoders found across the web.</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p style={{ fontWeight: 'bold' }}>Cyber Chef</p>
                            <p>The Cyber Swiss Army Knife - a web app for encryption, encoding, compression and data analysis.</p>
                            <p style={{ color: "#5797b7" }}>https://gchq.github.io/CyberChef/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://gchq.github.io/CyberChef/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style={{ fontWeight: 'bold' }}>Cryptii</p>
                            <p>Modular conversion, encoding and encryption online.</p>
                            <p style={{ color: "#5797b7" }}>https://cryptii.com/</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`https://cryptii.com/`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style={{ fontWeight: 'bold' }}>dCode.fr</p>
                            <p>Tool to decrypt/encrypt with Caesar.</p>
                            <p style={{ color: "#5797b7" }}>https://www.dcode.fr/caesar-cipher</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`https://www.dcode.fr/caesar-cipher`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default CyberChef
