import React from 'react';
import { Link } from 'react-router-dom';
import TargetInput from '../pages/targetinput/TargetInput'
import VersionModal from '../pages/version/Version';
import { SiHackaday } from 'react-icons/si';
// import { IconContext } from 'react-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';
import '../pages/targetinput/targetinput.css'


function Navbar() {

    return (
        // <IconContext.Provider value={{ color: '#fff' }}>
        <div className='header'>
            <Link to='/'>
                <SiHackaday className='logo' />
            </Link>
            <Link to="/" className="text-logo">
                CTF ToolKit
                    </Link>
            <div className="target-input">
                <TargetInput />
            </div>
            <div>
                <VersionModal />
            </div>
        </div>
        // </IconContext.Provider>
    )
}

export default Navbar
