import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function GoBuster() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Gobuster</h4>
            <p className="mod-description">
                Gobuster is a tool used to brute-force URIs, DNS, Virtual Host names and Open Amazon S3 buckets.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Gobuster help. </p>
                            <p style={{ color: "#5797b7" }}>gobuster -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='gobuster -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Gobuster directory/file bruteforcing mode. </p>
                            <p style={{ color: "#5797b7" }}>gobuster dir -u http://{targetAddress} -w /usr/share/wordlists/dirb/common.txt</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`gobuster dir -u http://${targetAddress} -w /usr/share/wordlists/dirb/common.txt`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Gobuster VHOST bruteforcing mode. </p>
                            <p style={{ color: "#5797b7" }}>gobuster vhost -u http://{targetAddress} -w /usr/share/wordlists/dirb/common.txt</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`gobuster vhost -u http://${targetAddress} -w /usr/share/wordlists/dirb/common.txt`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Gobuster DNS bruteforcing mode. </p>
                            <p style={{ color: "#5797b7" }}>gobuster dns -d http://{targetAddress} -w /usr/share/wordlists/dirb/common.txt</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`gobuster dns -d http://${targetAddress} -w /usr/share/wordlists/dirb/common.txt`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Gobuster commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://github.com/OJ/gobuster</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://github.com/OJ/gobuster'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default GoBuster
