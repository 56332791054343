import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { MdDoNotDisturb } from 'react-icons/md';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import './flags.css';


function FlagThree() {

    const { handleSubmit, register, errors } = useForm();
    const onSubmit = values => (values);

    return (
        <Container>
            <div className="flag-container">
                <MdDoNotDisturb /> <HiArrowNarrowLeft />
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <input
                    name="text"
                    placeholder="{Flag}"
                    ref={register({
                        validate: value => value !== "{Time}" || "Success!"
                    })}
                />
                <div className="flag-submit-button">
                    <Button type="submit" variant="info">Submit</Button>
                </div>
            </Form>
            <div className="flag-success">
                {errors.text && errors.text.message}
            </div>
        </Container>
    );
}

export default FlagThree