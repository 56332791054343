import React from 'react';
import { NavLink } from 'react-router-dom';
import { BsEyeSlash } from 'react-icons/bs';
import { RiSearchEyeLine } from 'react-icons/ri';
import { GiNinjaHead } from 'react-icons/gi';
import { CgListTree } from 'react-icons/cg';
import { FaPizzaSlice } from 'react-icons/fa';
import { GiTrojanHorse } from 'react-icons/gi';

// import { CgNotes } from 'react-icons/cg';
import './navbar.css';

function Navbar() {
    return (
        <div className="navbar">
            <NavLink activeStyle={{ color: "rgb(65, 32, 65)" }} to="/checklist">
                <CgListTree />
            </NavLink>
            <NavLink activeStyle={{ color: "rgb(65, 32, 65)" }} to="/enumeration">
                <RiSearchEyeLine />
            </NavLink>
            <NavLink activeStyle={{ color: "rgb(65, 32, 65)" }} to="/exploits">
                <GiTrojanHorse />
            </NavLink>
            <NavLink activeStyle={{ color: "rgb(65, 32, 65)" }} to="/postexploit">
                <GiNinjaHead />
            </NavLink>
            <NavLink activeStyle={{ color: "rgb(65, 32, 65)" }} to="/encryption">
                <BsEyeSlash />
            </NavLink>
            <div className="donate-link">
                <a href="https://www.buymeacoffee.com/ctftoolkit" target="_blank">
                    <FaPizzaSlice />
                </a>
            </div>
        </div>
    )
}

export default Navbar
