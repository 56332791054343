import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import './version.css';


function VersionModal() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Link to="#" onClick={handleShow}>
                <div className="version-link">
                    v1.12.8
                </div>
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Release Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>================================</p>
                    <p>04-19-2021 v1.12.8</p>
                    <p>================================</p>
                    <p>// Added content</p>
                    <p>// Housekeeping</p>
                    <p>// RG9udCBzY2FuIG1lIGJybw==</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VersionModal;