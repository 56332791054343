import React from 'react'
import WhatWeb from './webapps/WhatWeb';
import Nikto from './webapps/Nikto';
import Dirb from './webapps/Dirb';
import GoBuster from './webapps/GoBuster';
import WPScan from './webapps/Wpscan';
import SQLMap from './webapps/Sqlmap';
import Wfuzz from './webapps/Wfuzz';



function WebApps() {
    return (
        <div>
            <WhatWeb />
            <Nikto />
            <Dirb />
            <GoBuster />
            <WPScan />
            <SQLMap />
            <Wfuzz />
        </div>
    )
}

export default WebApps
