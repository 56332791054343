import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import { FaPlusSquare } from 'react-icons/fa';
import Base64 from './encryption/Base64';
import Hex from './encryption/Hex';
import Rot13 from './encryption/Rot13'
import CyberChef from './encryption/CommunityCipherScripts';
import './pages.css';


function Encryption() {
    return (
        <div className="page-container">
            <div className="page-header">Encryption</div>
            <Accordion>
                <Card>
                    <Card.Header>
                        Base64
                            <Accordion.Toggle as={Button} variant="info" eventKey="0">
                            <FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Base64 />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Hex
                            <Accordion.Toggle as={Button} variant="info" eventKey="1">
                            <FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Hex />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Rot13
                            <Accordion.Toggle as={Button} variant="info" eventKey="2">
                            <FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Rot13 />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Community Scripts
                            <Accordion.Toggle as={Button} variant="info" eventKey="3">
                            <FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <CyberChef />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default Encryption
