import React from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import { TargetAddressContext } from '../../state/contexts';


function TargetInput() {

    // const [getTarget, setGetTarget] = useState(localStorage.getItem('targetInput'));

    // const handleChange = e => {
    //     setGetTarget(e.target.value);
    //     localStorage.setItem('targetInput', e.target.value);
    // }


    return (
        <TargetAddressContext.Consumer>
            {({ targetAddress, updateTargetAddress }) => (
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Target</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl id="targetInput"
                        value={targetAddress}
                        onChange={(event) => updateTargetAddress(event.target.value)} placeholder="Ip or Domain" />
                    {/* {targetAddress} */}
                </InputGroup>
            )}
        </TargetAddressContext.Consumer>
    )
}

export default TargetInput