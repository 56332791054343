import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function PingSweep() {

    return (
        <div className="mod-container">
            <h4>Ping Sweep</h4>
            <p className="mod-description">
                Host discovery.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Ping Sweep </p>
                            <p style={{ color: "#5797b7" }}>wget https://raw.githubusercontent.com/aryanguenthner/365/master/pingsweep.sh</p>
                            <p>chmod +x pingsweep.sh</p>
                            <p>./pingsweep.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`wget https://raw.githubusercontent.com/aryanguenthner/365/master/pingsweep.sh`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default PingSweep
