import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function LinuxEnum() {
    return (
        <div className="mod-container">
            <h4>LinEnum</h4>
            <p className="mod-description">
                A script to enumerate local information from a Linux host.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Use wget to grab the script from github.</p>
                            <p style={{ color: "#5797b7" }}>wget https://raw.githubusercontent.com/rebootuser/LinEnum/master/LinEnum.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='wget https://raw.githubusercontent.com/rebootuser/LinEnum/master/LinEnum.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Make excutable.</p>
                            <p style={{ color: "#5797b7" }}>chmod +x LinEnum.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='chmod +x LinEnum.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>LinEnum help (-h) </p>
                            <p style={{ color: "#5797b7" }}>./LinEnum.sh -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='./LinEnum.sh -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Run the script.</p>
                            <p style={{ color: "#5797b7" }}>./LinEnum.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='./LinEnum.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Run the script and export the results to a file.</p>
                            <p style={{ color: "#5797b7" }}>./LinEnum.sh -r report -e /tmp/ -t</p>
                        </td>
                        <td>
                            <CopyToClipboard text='./LinEnum.sh -r report -e /tmp/ -t'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of LinEnum commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://github.com/rebootuser/LinEnum</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://github.com/rebootuser/LinEnum'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default LinuxEnum
