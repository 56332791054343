import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PrintJs from 'print-js';
// import { TargetAddressContext } from '../../state/contexts';
// import classes from './notes.css';

function NotepadData() {

    // const { targetAddress } = useContext(TargetAddressContext)

    // to store the texts that user type
    // 'text' is used to store the value and 'setText' is used to change 'text' with new value.
    // We are initializing 'text' with the values from localstorage.
    const [text, setText] = useState(localStorage.getItem('notepad'));

    const handleChange = e => {
        setText(e.target.value);
        localStorage.setItem('notepad', e.target.value);
    }

    const downloadAsTxt = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <div>
            <textarea
                className="notes-textarea"
                id="notepad"
                value={text}
                placeholder="Notes"
                onChange={handleChange}>
            </textarea>
            <Button style={{ marginRight: 20 }} size="sm" onClick={() => PrintJs('notepad', 'html')} variant="info">Print</Button>
            <Button size="sm" onClick={() => downloadAsTxt('ctftookit-notes', text)} variant="info">Download</Button>
        </div>
    )
}

export default NotepadData