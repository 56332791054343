import React from 'react'
import SuidLocate from './community/SuidLocate';





function CommunityPrivEscScripts() {
    return (
        <div>
            <SuidLocate />
        </div>
    )
}

export default CommunityPrivEscScripts
