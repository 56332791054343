import React from 'react'
import LinuxPrivesc from './privesc/LinuxPrivesc';
import GtfoBins from './privesc/GtfoBins';
import LinExpSuggest from './privesc/LinExpSuggest';
import LinEnum from './privesc/LinEnum';

function LinuxPrivEsc() {
    return (
        <div>
            <LinuxPrivesc />
            <GtfoBins />
            <LinExpSuggest />
            <LinEnum />
        </div>
    )
}

export default LinuxPrivEsc
