import React from 'react'
// import WindowsPrivesc from './privesc/WindowsPrivesc';
import LolBas from './privesc/LolBas';


function WindowsPrivEsc() {
    return (
        <div>
            {/* <WindowsPrivesc /> */}
            <LolBas />
        </div>
    )
}

export default WindowsPrivEsc
