import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function SuidLocate() {
    return (
        <div className="mod-container">
            <h4>SUID Locate</h4>
            <p className="mod-description">
                Checks for files with SUID permissions.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Use wget from the target box to grab SUID Locate from github.</p>
                            <p style={{ color: "#5797b7" }}>wget https://raw.githubusercontent.com/NoDisassemble/Bash-PenTesting/master/PrivEsc/SuidLocate.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`wget https://raw.githubusercontent.com/NoDisassemble/Bash-PenTesting/master/PrivEsc/SuidLocate.sh`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Make excutable.</p>
                            <p style={{ color: "#5797b7" }}>chmod +x SuidLocate.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='chmod +x SuidLocate.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Run the script.</p>
                            <p style={{ color: "#5797b7" }}>./SuidLocate.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='./SuidLocate.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default SuidLocate
