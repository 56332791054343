import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import LinuxPrivEsc from './postexploit/LinuxPrivEsc';
import WindowsPrivEsc from './postexploit/WindowsPrivEsc';
import Persistence from './postexploit/Persistence';
import CommunityPrivEscScripts from './postexploit/CommunityPrivEscScripts';
import './pages.css';

function PostExploit() {

    return (
        <div className="page-container">
            <div className="page-header">Post Exploitation</div>
            <Accordion>
                <Card>
                    <Card.Header>
                        Linux PrivEsc
                            <Accordion.Toggle as={Button} variant="info" eventKey="0">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body><LinuxPrivEsc /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Windows PrivEsc
                            <Accordion.Toggle as={Button} variant="info" eventKey="1">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body><WindowsPrivEsc /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Persistence
                            <Accordion.Toggle as={Button} variant="info" eventKey="2">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body><Persistence /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Community Scripts
                            <Accordion.Toggle as={Button} variant="info" eventKey="3">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body><CommunityPrivEscScripts /></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}


export default PostExploit;