import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function LinExpSuggest() {
    return (
        <div className="mod-container">
            <h4>LES | Linux Exploit Suggester</h4>
            <p className="mod-description">
                Linux Exploit Suggester [LES] is designed to assist in detecting security deficiencies for given Linux kernel/Linux-based machine.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Use wget to grab LES from github.</p>
                            <p style={{ color: "#5797b7" }}>wget https://raw.githubusercontent.com/mzet-/linux-exploit-suggester/master/linux-exploit-suggester.sh -O les.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='wget https://raw.githubusercontent.com/mzet-/linux-exploit-suggester/master/linux-exploit-suggester.sh -O les.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Make excutable.</p>
                            <p style={{ color: "#5797b7" }}>chmod +x les.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='chmod +x les.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Run the script.</p>
                            <p style={{ color: "#5797b7" }}>./les.sh</p>
                        </td>
                        <td>
                            <CopyToClipboard text='./les.sh'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of LES commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://mzet-.github.io/2019/05/10/les-paper.html</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://mzet-.github.io/2019/05/10/les-paper.html'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default LinExpSuggest
