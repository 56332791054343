

export const convertStringToRot13 = str => str.split('')
    .map(char => String.fromCharCode(
        char.charCodeAt(0) + (char.toLowerCase() < 'n' ? 13 : -13))).join("");


export const convertRot13ToString = str => str.split('')
    .map(char => String.fromCharCode(
        char.charCodeAt(0) + (char.toLowerCase() < 'n' ? 13 : -13))).join("");

