import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Encryption from './pages/Encryption';
import Enumeration from './pages/Enumeration';
import PostExploit from './pages/PostExploit';
import Exploits from './pages/Exploits';
import Notes from './pages/Notes';
import CheckList from './pages/CheckList';
import FlagOne from './pages/flags/FlagOne';
import FlagTwo from './pages/flags/FlagTwo';
import FlagThree from './pages/flags/FlagThree';
import FlagFour from './pages/flags/FlagFour';
import FlagFive from './pages/flags/FlagFive';
import FlagSix from './pages/flags/FlagSix';
import FlagSeven from './pages/flags/FlagSeven';
import FlagEight from './pages/flags/FlagEight';
import NoMatch from './pages/NoMatch';
import { TargetAddressContext } from './state/contexts';
import './App.css';


function App() {

  const [targetAddress, updateTargetAddress] = useState("example.com")

  return (
    <Container fluid>
      <TargetAddressContext.Provider value={{ targetAddress, updateTargetAddress }}>
        <Router>
          <Header />
          <Row>
            <Col sm={1}>
              <NavBar />
            </Col>
            <Col sm={8}>
              <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/checklist' component={CheckList} />
                <Route path='/enumeration' component={Enumeration} />
                <Route path='/exploits' component={Exploits} />
                <Route path='/postexploit' component={PostExploit} />
                <Route path='/encryption' component={Encryption} />
                <Route path='/notes' component={Notes} />
                <Route path='/nothingtoseehere' component={FlagOne} />
                <Route path='/whatgoesup' component={FlagTwo} />
                <Route path='/whatmovesforward' component={FlagThree} />
                <Route path='/whatsinfrontofyou' component={FlagFour} />
                <Route path='/themorethereis' component={FlagFive} />
                <Route path='/whathashands' component={FlagSix} />
                <Route path='/whathaskeys' component={FlagSeven} />
                <Route path='/whathasbranches' component={FlagEight} />
                {/* <Route path='*' component={NoMatch} /> */}
                <Route component={NoMatch} />
              </Switch>
            </Col>
            <Col sm={3}>
              <Notes />
            </Col>
          </Row>
        </Router>
      </TargetAddressContext.Provider >
    </Container>
  );
}

export default App;