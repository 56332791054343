import React from 'react'
import WPScan from './passwordattacks/Wpscan';
import Sql from './passwordattacks/Sql';
import Hydra from './passwordattacks/Hydra';
import Hashcat from './passwordattacks/Hashcat';
import JohnTheRipper from './passwordattacks/JohnTheRipper';


function PasswordAttacks() {
    return (
        <div>
            <WPScan />
            <Sql />
            <Hydra />
            <Hashcat />
            <JohnTheRipper />
        </div>
    )
}

export default PasswordAttacks
