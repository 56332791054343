import React from 'react'
import Nmap from './networks/Nmap';



function Networks() {
    return (
        <div>
            <Nmap />
        </div>
    )
}

export default Networks
