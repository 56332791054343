import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function Crunch() {

    return (
        <div className="mod-container">
            <h4>Crunch</h4>
            <p className="mod-description">
                Crunch is a wordlist generator where you can specify a standard character set or a character set you specify. crunch can generate all possible combinations and permutations.
                </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Crunch help.</p>
                            <p style={{ color: "#5797b7" }}>crunch</p>
                        </td>
                        <td>
                            <CopyToClipboard text='crunch'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Generate a dictionary file containing words with a minimum and maximum length of 6 (6 6) using the given characters (0123456789abcdef), saving the output to a file (crunchwords.txt):</p>
                            <p style={{ color: "#5797b7" }}>crunch 6 6 0123456789abcdef -o crunchwords.txt</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`crunch 6 6 0123456789abcdef -o crunchwords.txt`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Crunch commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/password-attacks/crunch</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/password-attacks/crunch'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Crunch
