import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TargetAddressContext } from '../../../state/contexts';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function EnumForLinux() {

    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Enum4Linux</h4>
            <p className="mod-description">
                Enum4linux is a tool for enumerating information from Windows and Samba systems. It attempts to offer similar functionality to enum.exe formerly available from www.bindview.com.</p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Enum4linux help. (-h) </p>
                            <p style={{ color: "#5797b7" }}>enum4linux -h</p>
                        </td>
                        <td>
                            <CopyToClipboard text='enum4linux -h'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Enum4Linux full scan. (Enumerates everything)</p>
                            <p style={{ color: "#5797b7" }}>enum4linux {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`enum4linux ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Enum4Linux scan for userlist (-U) and OS information (-o).</p>
                            <p style={{ color: "#5797b7" }}>enum4linux -U -o {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`enum4linux -U -o ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Enum4Linux commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/information-gathering/enum4linux</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/information-gathering/enum4linux'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default EnumForLinux;