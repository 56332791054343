import React from 'react'
import ReverseShells from './persistence/ReverseShells';
import WebShells from './persistence/Webshells';
import KaliShells from './persistence/KaliShells';




function Persistence() {
    return (
        <div>
            <ReverseShells />
            <WebShells />
            <KaliShells />
        </div>
    )
}

export default Persistence
