import React from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import '../../pages.css';

function WebRecon() {


    return (
        <div className="mod-container">
            <h4>Web Recon 2.0</h4>
            <p className="mod-description">
                This script will run Whatweb, Nmap, Nikto and Dirb then output all results to separate files.
            </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Web Recon 2.0 </p>
                            <p style={{ color: "#5797b7" }}>wget https://raw.githubusercontent.com/NoDisassemble/Python-3-PenTesting/master/Web%20Recon/WebReconV2.py</p>
                            <p>Must run as root</p>
                            <p>python3 WebReconV2.py</p>
                            <p>Enter target: target.com or IP</p>
                            <p>Go for a walk, this will take a while.</p>
                        </td>
                        <td>
                            <CopyToClipboard text='wget https://raw.githubusercontent.com/NoDisassemble/Python-3-PenTesting/master/Web%20Recon/WebReconV2.py'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default WebRecon
