import React from 'react'
import HashIdentifier from './hashes/Hashidentifier';


function Hashes() {
    return (
        <div>
            <HashIdentifier />
        </div>
    )
}

export default Hashes
