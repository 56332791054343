import React from 'react'
import DNSRecon from './dns/DNSRecon';
import DNSEnum from './dns/DNSEnum';
import Dig from './dns/Dig';



function Dns() {
    return (
        <div>
            <DNSRecon />
            <DNSEnum />
            <Dig />
        </div>
    )
}

export default Dns
