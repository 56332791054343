import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import Networks from './enumeration/Networks';
import Assets from './enumeration/Assets';
import WebApps from './enumeration/WebApps';
import Dns from './enumeration/Dns';
import Hashes from './enumeration/Hashes';
import CommunityEnumScripts from './enumeration/CommunityEnumScripts';
import './pages.css';

function Enumeration() {

    return (
        <div className="page-container">
            <div className="page-header">Enumeration</div>
            <Accordion>
                <Card>
                    <Card.Header>
                        Networks
                            <Accordion.Toggle as={Button} variant="info" eventKey="0">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body><Networks /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Assets
                            <Accordion.Toggle as={Button} variant="info" eventKey="1">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body><Assets /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Web Apps
                            <Accordion.Toggle as={Button} variant="info" eventKey="2">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body><WebApps /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        DNS
                            <Accordion.Toggle as={Button} variant="info" eventKey="3">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body><Dns /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Hashes
                            <Accordion.Toggle as={Button} variant="info" eventKey="5">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body><Hashes /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        Community Scripts
                            <Accordion.Toggle as={Button} variant="info" eventKey="6">
                            <FaIcons.FaPlusSquare />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body><CommunityEnumScripts /></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}


export default Enumeration;
