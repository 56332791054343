import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import { TargetAddressContext } from '../../../state/contexts';
import '../../pages.css';

function Nmap() {

    // const [showTooltip, setShowTooltip] = useState(false)
    // const [show, setShow] = useState(false);
    const { targetAddress } = useContext(TargetAddressContext)

    return (
        <div className="mod-container">
            <h4>Nmap</h4>
            <p className="mod-description">
                Nmap (“Network Mapper”) is a free and open source utility for network discovery, scanning and security auditing.
                </p>
            <Table striped variant="dark">
                <tbody>
                    <tr>
                        <td>
                            <p>Nmap help. (--help)</p>
                            <p style={{ color: "#5797b7" }}>nmap --help</p>
                        </td>
                        <td>
                            <CopyToClipboard text='nmap --help'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Host discovery only. (-sn) </p>
                            <p style={{ color: "#5797b7" }}>nmap -sn {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`nmap -sn ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Port scan all ports. (-p-) </p>
                            <p style={{ color: "#5797b7" }}>nmap -p- {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`nmap -p- ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Scan in verbose mode (-v), enable OS detection, version detection, script scanning, and traceroute (-A), with version detection (-sV) and all ports (-p-) against the target IP </p>
                            <p style={{ color: "#5797b7" }}>nmap -v -A -sV -p- {targetAddress}</p>
                        </td>
                        <td>
                            <CopyToClipboard text={`nmap -v -A -sV -p- ${targetAddress}`}>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>A more extensive list of Nmap commands can be found here:</p>
                            <p style={{ color: "#5797b7" }}>https://tools.kali.org/information-gathering/nmap</p>
                        </td>
                        <td>
                            <CopyToClipboard text='https://tools.kali.org/information-gathering/nmap'>
                                <div className="ctc-icon">
                                    <FaRegCopy />
                                </div>
                            </CopyToClipboard>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Nmap
